import { createRouter, createWebHistory } from 'vue-router'
// import Home from '../views/Home.vue'
import Question from '../views/Question.vue'
import AskQuestion from '../views/AskQuestion.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    // component: Home
    redirect: '/ask_question'
  },

  {
    path: '/question/:key',
    name: 'Question',
    component: Question
  },
  {
    path: '/ask_question/:id',
    name: 'AskQuestion',
    component: AskQuestion
  },

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
