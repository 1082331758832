<template>
    <div class="wrapper">
        <question-head tip="快给我匿名悄悄话 or 提问吧～" v-bind:from="from" />
        <div class="textarea_parent_div">
            <textarea class="ahh_textarea" placeholder="匿名编辑悄悄话 or 提问…" v-model="content"></textarea>
        </div>
        
        <div>
            <div class="send_button" @click="createQs">匿名发送</div>
        </div>
        <div class="line"> </div>
        <div class="open_box" @click="openAppInH5" v-show="hidshow">开通我的啊留言箱</div>
        <img class="masking" src="../assets/masking.png" v-if="showMasking" />
    </div>
</template>

<script>
import QuestionHead from '@/components/QuestionHead.vue'
import { createQuestion } from '../request/question_api'
import { openApp, isInWeibo } from '../common/market'

export default {
    name: 'AskQuestion',
    components: {
        QuestionHead
    },

    data() {
        return {
            content:"",
            from: "",
            docmHeight: 0,  
            maxHeight: 0,
            hidshow:true,    //显示或者隐藏footer,
            showMasking: false
        }
    },

    mounted() {
        this.computeSize()
        window.onresize = ()=> {
            return (() => {
                this.computeSize()
            })();
        }

    },

    created() {
        this.from = "——" + this.$route.query.name + "的留言箱"
        document.title = this.$route.query.name + "的留言箱"
    },

    watch: {
        docmHeight() {
            if(this.docmHeight < this.maxHeight){
                this.hidshow=false
            }else{
                this.hidshow=true
             }
        },
    },
    
    methods: {

        computeSize() {
            this.docmHeight = document.documentElement.clientHeight 
                
            if (this.maxHeight < this.docmHeight) {
                this.maxHeight = this.docmHeight
            }
        },

        createQs() {
            if(this.content.length == 0) {
                this.$toast('内容不能为空!');
            } else {
                createQuestion(this.content, this.$route.params.id).then(
                    (res) => {
                        if (res.status == 200) {
                            var key = res.data.key;
                            console.log(key);
                            if(key != null && key != undefined && key.length > 0) {
                                this.$router.push({ path: '/question/' + key, query: { name: this.$route.query.name } });
                            }
                        }
                        console.log(res);
                    }
                );
            }
        },

        openAppInH5() {
            this.showMasking = isInWeibo()
            if (!this.showMasking) {
                openApp("")
            }
        }
    }
}
</script>

<style scoped>
    html,body{
        height:100%
    }

    .wrapper{
        height: 100%;
        width: 100%;
        position: fixed;
        margin: 0;
        padding: 0;
        left: 0;
        top: 0;
        background-color: white;
    }

    .textarea_parent_div {
        margin-top: 30px;
        margin-left: 26px;
        margin-right: 26px;
    }

    .ahh_textarea {
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: #000000;
        letter-spacing: -0.62px;
        width: 100%;
        height: 210px;
        border: none;
        outline: none;
    }

    .send_button {
        opacity: 0.93;
        font-family: PingFangSC-Medium;
        font-size: 14px;
        color: #FFFFFF;
        letter-spacing: 0;
        width: 88px;
        height: 30px;
        line-height: 30px;
        background: #F8566E;
        border-radius: 26px;
        border-radius: 26px;
        text-align: center;
        margin-top: 20px;
        right: 26px;
        position: absolute;
    }

    .line {
        margin-top: 60px;
        background-color:#E8EAEC;
        height: 1px;
        opacity: 0.8;
        margin-left: 15px;
        margin-right: 0px;
    }

    .open_box {
        font-family: PingFangSC-Medium;
        font-size: 16px;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: center;
        left: 20px;
        right: 20px;
        position: absolute;
        bottom: 30px;
        height: 46px;
        line-height: 46px;
        border-radius: 23px;
        background: #F61B3C;
    }

    .masking {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
    }
</style>