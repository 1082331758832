import axios from 'axios'

export function createQuestion(content, toUserId) {
    let params =  {
        'content': content,
        'to_user_id': toUserId
    };

    return axios.post(
        '/api/v1/questions', params
    )
}