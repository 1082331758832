
export function isAndroid() {
    var ua = navigator.userAgent; 
    return ua.indexOf('Android') > -1 || ua.indexOf('Linux') > -1; 
}

export function isIOS() {
    var ua = navigator.userAgent; 
    return !!ua.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); 
}

export function isInWeChat() {
    var ua = navigator.userAgent.toLowerCase(); 
    return (ua.match(/MicroMessenger/i) == 'micromessenger')
}

export function isInWeibo() {
    var ua = navigator.userAgent.toLowerCase(); 
    return (ua.match(/WeiBo/i) == "weibo")
}

export function isInQQ() {
    var ua = navigator.userAgent.toLowerCase(); 
    return (ua.match(/QQ/i) == "qq")
}

export function openMarket() {
    if (isInWeChat()) {
        console.log("isInWeChat");
    } else if (isAndroid()) {
        window.location.href = "https://aquanapp.oss-cn-beijing.aliyuncs.com/apk/release/latest/app-release.apk";
    } else if (isIOS()) {
        window.location.href = "https://apps.apple.com/cn/app/id1441405700";
    }
}

export function openApp(key) {
    if(key != undefined && key != null && key.length > 0) {
        window.location.href = 'ahh://ahh.weavi.com.cn/question?code=' + key
    } else {
        window.location.href = 'ahh://ahh.weavi.com.cn/question'
    }
    
    toOpenMarket()
}

export function toOpenMarket() {
    let ans = window.setTimeout(() => {
        openMarket()
    }, 400);

    var lastFired = new Date().getTime();
    window.setInterval(()=>{
        let now = new Date().getTime();
        if(now - lastFired > 300) {
            window.clearTimeout(ans);
        }
        lastFired = now;
    }, 200);
}


/** 
export function openAppInHtml5() {
    setTimeout(function(){
        openApp.onclick()
    }, 200)
 
    var openApp = document.querySelector('#open')
    openApp.onclick = function() {
        var ifr = document.createElement('iframe');
        ifr.src = scheme
        ifr.style.display = 'none'
        document.body.appendChild(ifr)
        window.setTimeout(function() {
            document.body.removeChild(ifr)
        }, 3000);
        window.location.href = ifr.src
    };
}
*/