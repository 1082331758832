<template>
    <div class="question_font question_pos"> {{ tip }}</div>
    <div class="question_font name_pos"> {{ from }}</div>
    <div class="line"> </div>
</template>

<script>
export default {
    name: 'QuestionHead',
    props: {
        tip: String,
        from: String
    }
}
</script>


<style scoped>

    .question_font {
        font-family: PingFangSC-Regular;
        font-size: 14px;
        color: #000000;
        letter-spacing: 0.3px;
    }

    .question_pos {
        text-align: start;
        margin-left: 26px;
        margin-right: 26px;
        margin-top: 20px;
    }

    .name_pos {
        text-align: end;
        margin-right: 26px;
        margin-top: 10px;
    }

    .line {
        margin-top: 10px;
        background-color:#E8EAEC;
        height: 1px;
        opacity: 0.8;
        margin-left: 15px;
        margin-right: 0px;
    }

</style>