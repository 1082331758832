import axios from 'axios'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import DKToast from 'vue-dk-toast';

axios.defaults.baseURL = process.env.VUE_APP_STARTS_SERVER
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

createApp(App).use(store).use(router).use(DKToast,{
    duration: 2000,
    styles: {
        color: '#fff',
        backgroundColor: '#000000A6',
        borderRadius: '8px',
        marginBottom: "150px",
        marginLeft: '40px',
        marginRight: '40px'
    }
}).mount('#app')
