<template>
<div class="wrapper">
    <question-head tip="快给我匿名悄悄话 or 提问吧～" v-bind:from="from" />

    <div class = "content">
        <p>已发送</p>
        <p>复制信件编码并在啊app内绑定可第一时间收到回信，不绑定无法查看对方私密回信～ </p>
        <p>信件编码：{{ key }}  </p>
        
        <!-- 按钮 -->   
        <div class="copy_button" @click="copyAndOpen">复制并打开啊app</div>
        <div class="open_box" @click="openAppInH5">开通我的啊留言箱</div>
    </div>
     <img class="masking" src="../assets/masking.png" v-if="showMasking" />
</div>
    
</template>

<script>
import QuestionHead from '@/components/QuestionHead.vue'
import useClipboard from 'vue-clipboard3'
import { openApp, isInWeibo } from '../common/market'

export default {
    name: 'Question',
    components: {
        QuestionHead
    },

    data() {
        return {
            key:"",
            innerHTML: "",
            from:"",
            showMasking:false
        }
    },

    methods: {
        copyAndOpen() {
            // this.innerHTML='<iframe src="ahh://ahh.weavi.com.cn/question"></iframe>'; 
            this.showMasking = isInWeibo()
            if (!this.showMasking) {
                const { toClipboard } = useClipboard()
                try {
                    toClipboard(this.key)
                } catch (e) {
                    console.error(e)
                }
                this.openAppInH5()
            }
        },


        openAppInH5() {
            this.showMasking = isInWeibo()
            if (!this.showMasking) {
                openApp(this.key)
            }
        }
    },

    created() {
        this.key = this.$route.params.key
        this.from = "——" + this.$route.query.name + "的留言箱"
        document.title = this.$route.query.name + "的留言箱"
    }
}
</script>

<style scoped>

    .wrapper{
        height: 100%;
        width: 100%;
        position: fixed;
        margin: 0;
        padding: 0;
        left: 0;
        top: 0;
        background-color: white;
    }

    .content {
        font-family: PingFangSC-Regular;
        font-size: 16px;
        color: #000000;
        letter-spacing: 0.34px;
        text-align: start;
        margin-left: 26px;
        margin-right: 47px;
    }

    .copy_button {
        font-family: PingFangSC-Semibold;
        font-size: 16px;
        color: #000000;
        letter-spacing: 0;
        text-align: center;
        align-self: center;
        margin: 0 auto;
        margin-top: 20px;
        width: 170px;
        height: 46px;
        line-height: 46px;
        border-radius: 23px;
        background-color:#FFFFFF;
        box-shadow: 0 0 10px rgba(0, 0, 0, .5);
    }

    .open_box {
        font-family: PingFangSC-Medium;
        font-size: 16px;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: center;
        left: 20px;
        right: 20px;
        position: absolute;
        bottom: 30px;
        height: 46px;
        line-height: 46px;
        border-radius: 23px;
        background: #F61B3C;
    }

    .masking {
        position: absolute;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
    }


</style>